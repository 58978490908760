import React from "react";

import CSSBaseline from "@material-ui/core/CssBaseline";

import BX24 from "./services/bitrix24";
import VisitPanel from "./container/VisitPanel";
import NavAppBar from "./display/NavAppBar";
import Panel from "./display/Panel";
import {
  BoletoBarcodeReader,
  BoletoBarcodeReaderProvider,
  isSmartDevice
} from "react-boleto-reader";
import "react-boleto-reader/dist/index.css";
import "./styles.css";

import AppContext from "./context/AppContext";

const App = () => {
  const [appData, setAppData] = React.useState({});

  const [boleto, setBoleto] = React.useState(null);
  const [reading, setReading] = React.useState(false);

  const onDetected = React.useCallback(
    (detectedBoleto) => {
      setBoleto(null);
      setReading(false);

      if (boleto) {
        return;
      }

      console.log(
        "\n\nTODOS OS DADOS DO BOLETO NO CONSOLE: \n",
        detectedBoleto,
        "\n\n"
      );

      setBoleto(detectedBoleto);
    },
    [boleto]
  );

  const onCancel = React.useCallback(() => {
    setBoleto(null);
    setReading(false);
  }, []);

  const restart = React.useCallback(() => {
    setTimeout(() => {
      setBoleto(null);
      setReading(true);
    }, 250);
  }, []);

  /* Uses Bitrix24 API to fetch fields present in multiple elements to be used in form VisitForm as select input options. */
  // React.useEffect(() => {
  //   Promise.all([
  //     BX24.get("lists.field.get", {
  //       params: {
  //         IBLOCK_TYPE_ID: "lists",
  //         IBLOCK_ID: 32,
  //       },
  //     }),
  //     BX24.get("crm.lead.fields"),
  //   ])
  //     .then((responses) =>
  //       setAppData({
  //         listFields: responses[0].data.result,
  //         leadFields: responses[1].data.result,
  //       })
  //     )
  //     .catch((responses) => console.log(responses));
  // }, []);

  return (
    <React.Fragment>
      <CSSBaseline />
      <NavAppBar />
      <Panel>
        <AppContext.Provider value={appData}>
          <VisitPanel />
        </AppContext.Provider>
      </Panel>
      <Panel>
        <AppContext.Provider value={appData}>
        <main style={{ textAlign: "center" }}>
          <section>
            {!isSmartDevice ? (
              <>Falha no dispositivo.</>
            ) : (
              <>
                {!reading && !boleto && (
                  <button type="button" id="read_start" onClick={restart}>
                    Ler Boleto
                  </button>
                )}

                {reading && (
                  <BoletoBarcodeReaderProvider>
                    <BoletoBarcodeReader
                      id="bora-ler-um-boleto-maroto"
                      height={`${window.innerHeight}px`}
                      onDetected={onDetected}
                      onCancel={onCancel}
                    />
                  </BoletoBarcodeReaderProvider>
                )}

                {boleto && (
                  <article id="result">
                    <pre
                      dangerouslySetInnerHTML={{
                        __html: JSON.stringify(boleto || {})
                          .replace(/{/g, "{<br />  ")
                          .replace(/":/g, '": ')
                          .replace(/,"/g, ',<br />  "')
                          .replace(/}/g, "<br />}")
                      }}
                      style={{
                        display: "block",
                        width: "100%",
                        padding: "20px",
                        background: "#f8f8f8",
                        overflow: "auto",
                        textAlign: "left"
                      }}
                    />
                    <footer id="result_footer">
                      <button type="button" id="read_again" onClick={restart}>
                        Read Again
                      </button>
                    </footer>
                  </article>
                )}
              </>
            )}
          </section>
        </main>
        </AppContext.Provider>
      </Panel>
    </React.Fragment>

    
  );
};

export default App;
